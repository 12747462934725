import React, { useState } from "react";
import { ArrowSmLeftIcon } from "@heroicons/react/outline";
import {
  Text,
  RecoverStepone,
  RecoverSteptwo,
  RecoverStepthree,
  RecoverStepfour,
} from "../../components";
import { useTranslation } from "react-i18next";

export default function RecoverAccount({ token }) {
  const [recoverStep, setRecoverStep] = useState(0);
  const [phone, setPhone] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [method, setMethod] = useState(0);
  const [showEmailSent, setShowEmailSent] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 sm:place-items-center h-full sm:px-6">
      <div className="w-full h-full flex flex-col p-2 sm:h-auto sm:max-w-4xl sm:rounded-md sm:p-6 sm:w-9/12 sm:ring-2 sm:ring-gray-200">
        <div className="flex flex-row justify-between sm:h-20">
          <div>
            <Text size="xl" weight="bold">
              {t("auth.recoverAccount")}
            </Text>

            {!token ? (
              recoverStep === 0 ? (
                <Text size="sm" color="text-gray-400">
                  {t("auth.recoverMethod")}
                </Text>
              ) : recoverStep === 1 ? (
                method === 0 ? (
                  !showEmailSent ? (
                    <Text size="sm" color="text-gray-400">
                      {t("auth.emailRecovery")}
                    </Text>
                  ) : (
                    ""
                  )
                ) : (
                  <Text size="sm" color="text-gray-400">
                    {t("auth.phoneRecovery")}
                  </Text>
                )
              ) : recoverStep === 2 ? (
                <Text size="sm" color="text-gray-400">
                  {t("auth.codeSent")}
                </Text>
              ) : (
                <Text size="sm" color="text-gray-400">
                  {t("auth.newPassword")}
                </Text>
              )
            ) : RecoverStepfour ? (
              <Text size="sm" color="text-gray-400">
                {t("auth.nvPassword")}
              </Text>
            ) : (
              ""
            )}
          </div>
          {recoverStep !== 0 && !showEmailSent && (
            <div>
              <ArrowSmLeftIcon
                className="w-6 cursor-pointer hover:opacity-50"
                onClick={() => setRecoverStep(0)}
              />
            </div>
          )}
        </div>

        {recoverStep === 0 && !token ? (
          <div>
            <RecoverStepone
              setRecoverStep={setRecoverStep}
              setMethod={setMethod}
            />
          </div>
        ) : recoverStep === 1 && !token ? (
          <RecoverSteptwo
            setRecoverStep={setRecoverStep}
            method={method}
            setPhone={setPhone}
            setShowEmailSent={setShowEmailSent}
            showEmailSent={showEmailSent}
          />
        ) : recoverStep === 2 && !token ? (
          <RecoverStepthree
            setRecoverStep={setRecoverStep}
            phone={phone}
            setConfirmationCode={setConfirmationCode}
          />
        ) : (
          <RecoverStepfour
            phone={phone}
            confirmationCode={confirmationCode}
            token={token}
          />
        )}
      </div>
    </div>
  );
}
